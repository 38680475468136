.smartPanel h2 {
  font-family: "Poppins", sans-serif;
  color: #356597;
  text-align: center;
  font-size: 9vw;
}
.smartPanel p {
  margin-top: -10vw;
  font-family: "Poppins", sans-serif;
  text-align: center;
  padding: 8vw;
  font-size: 3.8vw;
}
.smartPanel ul {
  color: #c69920;
  margin-top: 5vw;
}

.smartPanel span {
  font-family: "Be Vietnam", sans-serif;
  color: #000000;
  font-size: 4.5vw;
}
.smartPanel img {
  width: 100%;
}
@media screen and (min-width: 1001px) {
  .smartPanel {
    display: grid;
    grid-template-columns: 50% 50%;
  }

  .smartPanel img:nth-child(3) {
    grid-column: 1/1;
    grid-row: 1;
    width: 80%;
    justify-self: center;
  }

  .smartPanel h2 {
    grid-column: 2/2;
    grid-row: 1;
    font-size: 3vw;
  }
  .smartPanel p {
    color: #353535;
    grid-column: 2/2;
    grid-row: 1;
    margin-top: -2vw;
    width: 90%;
    justify-self: center;
    font-size: 1.5vw;
  }

  .smartPanel ul {
    color: #c69920;
    grid-column: 2/2;
    grid-row: 1/1;
    margin-top: 12vw;
  }
  .smartPanel span {
    font-family: "Be Vietnam", sans-serif;
    color: #000000;
    font-size: 1.5vw;
  }
  .smartPanel img:nth-child(5) {
    grid-column: 2/2;
    grid-row: 1/1;
    margin-top: 25vw;
    width: 80%;
  }
}
