input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  font-size: 100%;
}

.fullPage {
  display: grid;
  /* grid-template-columns: 1fr; */
  /* grid-template-rows: 5vw; */
  font-family: "Roboto", "Poppins", sans-serif;
  overflow-x: hidden;
}

.fullPage .top-bar {
  background-color: #ffa902;
  padding: 3vw;
  transition: 0.4s;
  position: fixed;
  width: 100%;
  z-index: 99;
  overflow: hidden;
}

.slider-position {
  grid-column: 1/1;
  grid-row: 2/2;
  margin-top: -5.5vw;
}

.smartSecurity-position {
  margin-top: 8vw;
  grid-row: 3/3;
}

.form-position {
  display: inherit;
  margin-top: 10vw;
  grid-column: 1/1;
  grid-row: 4/4;
  justify-items: center;
  width: 100vw;
}

.smartPanel-position {
  grid-row: 5/5;
}

.takeControl-position {
  background: url("../images/takeControlBackgroundMobile.png") no-repeat;
  background-size: cover;
  grid-row: 6/6;
  padding-top: 20vw;
  padding-bottom: 5vw;
}
.ourApp-position {
  grid-row: 7/7;
}

.footer-position {
  grid-row: 8/8;
  background-color: #242424;
}

@media screen and (min-width: 720px) {
  .fullPage .top-bar {
    background-color: #ffa902;
    padding: 2vw;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    z-index: 99;
    overflow: hidden;
  }
  .slider-position {
    margin-top: -12vw;
  }
}

@media screen and (min-width: 1001px) {
  .fullPage {
    grid-template-columns: 30% 70%;
    grid-template-rows: 9vw;
  }

  .fullPage .top-bar {
    background-color: #ffa902;
    padding: 1vw;
    transition: 0.4s;
    position: fixed;
    width: 100%;
    z-index: 99;
    overflow: hidden;
    grid-column: 1/2;
    grid-row: 1/1;
  }

  .slider-position {
    grid-column: 1/3;
    grid-row: 2/2;
    margin-top: 7vw;
  }

  .smartSecurity-position {
    grid-column: 1/3;
    grid-row: 3/3;
    margin-top: 2vw;
  }

  .form-position {
    grid-column: 1/3;
    grid-row: 4/4;
    margin-top: 5vw;
    justify-items: center;
    width: 100%;
  }

  .smartPanel-position {
    grid-column: 1/3;
    grid-row: 5/5;
  }

  .takeControl-position {
    background: none;
    grid-column: 1/3;
    grid-row: 6/6;
    padding-top: 0vw;
    padding-bottom: 0vw;
  }

  .ourApp-position {
    grid-column: 1/3;
  }

  .footer-position {
    grid-column: 1/3;
  }
}
