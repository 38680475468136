.takeControl h2 {
  color: #07364c;
  font-family: "Poppins", sans-serif;
  font-size: 5.5vw;
  font-weight: bold;
  text-align: center;
}
.takeControl h3 {
  color: #1e83b2;
  font-family: "Be Vietnam", sans-serif;
  font-size: 5.5vw;
  text-align: center;
  font-weight: 600;
}
.takeControl img {
  width: 100%;
}

.indicator {
  display: grid;
  grid-template-columns: 30vw;
  min-height: 20vw;
}
.number {
  color: #1e83b2;
  font-family: "Be Vietnam", sans-serif;
  font-size: 5.5vw;
  font-weight: bold;
  grid-column: 1/1;
  text-align: center;
}
.panelInfo {
  font-family: "Be Vietnam", sans-serif;
  font-weight: 500;
  grid-column: 2/2;
}
.indicator img {
  grid-column: 1/3;
  grid-row: 2/2;
  width: 90%;
  justify-self: center;
  margin-top: -8vw;
}
.takeControl a {
  text-decoration: none;
}
.takeControl button {
  background-color: #ffffff;
  border: 0;
  border-radius: 24px;
  display: block;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 0.25));
  font-family: "Poppins", sans-serif;
  font-size: 5vw;
  font-weight: 500;
  margin: 5vw auto 5vw auto;
  padding: 3vw 1vw 3vw 1vw;
  width: 80%;
}

.takeControl button:active {
  filter: drop-shadow(0px 0px 0px);
  margin: 5.3vw auto 4.7vw auto;
}

.desktop {
  display: none;
}

@media screen and (min-width: 1001px) {
  .mobile {
    display: none;
  }
  .takeControl {
    margin-top: 0;
  }
  .takeControl h2 {
    display: block;
    position: absolute;
    font-size: 3vw;
    font-weight: bold;
    text-align: center;
    margin-top: 13vw;
    margin-left: 25vw;
  }

  .takeControl h3 {
    position: absolute;
    font-size: 3vw;
    text-align: center;
    font-weight: 600;
    margin-top: 17vw;
    margin-left: 28vw;
  }
  .desktop {
    display: initial;
  }
  .desktop img {
    margin-top: -5vw;
    width: 100%;
  }
  .takeControl button {
    position: absolute;
    width: 50%;
    font-size: 2vw;
    margin: -10vw auto auto 25vw;
    padding: 1vw 1vw;
  }

  .takeControl button:active {
    filter: drop-shadow(0px 0px 0px);
    margin: -9.7vw auto auto 25vw;
  }
}
