.slider-title {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 0.3fr 3fr;
  font-family: "Poppins", sans-serif;
}

.carousel {
  grid-column: 1/1;
  grid-row: 2/2;
  width: 100%;
  margin-top: 8vw;
}

.carousel-indicators li {
  display: none;
}

@media screen and (min-width: 1001px) {
  .carousel {
    width: 100%;
    align-self: center;
    margin-top: -5.5vw;
  }
}
