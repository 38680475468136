.logo {
  width: max-content;
  z-index: 999999;
  justify-self: left;
}

.logo img {
  margin: auto;
  width: 25vw;
}

@media screen and (min-width: 720px) {
  .logo img {
    width: 15vw;
  }
}
@media screen and (min-width: 1001px) {
  .logo {
    margin-top: 0%;
  }

  .logo img {
    display: block;
    margin: auto;
    width: 10vw;
  }
}
