.ourApp {
  background-color: #c1c5d0;
}
.ourApp .titles {
  display: grid;
  font-family: "Poppins", sans-serif;
  grid-template-columns: 25vw;
  position: absolute;
}
.titles h1 {
  margin-left: 2vw;
  font-weight: bold;
  grid-column: 2/2;
  grid-row: 1/1;
  font-size: 7vw;
  margin-bottom: 0vw;
  margin-top: 8vw;
}
.titles h2 {
  margin-left: 2vw;
  color: #003c78;
  font-weight: bold;
  grid-column: 2/2;
  grid-row: 2/2;
  font-size: 5vw;
  margin-bottom: 0vw;
}
.titles h3 {
  margin-left: 2vw;
  font-weight: 500;
  grid-column: 2/2;
  grid-row: 3/3;
  font-size: 4vw;
}
.titles img {
  margin-top: 7vw;
  grid-column: 1/1;
  grid-row: 1/4;
  justify-self: right;
}
.celphone-image img {
  width: 100%;
  margin-top: 0vw;
  z-index: -1;
}
@media screen and (min-width: 1001px) {
  .ourApp {
    margin-top: -1vw;
  }
  .ourApp .titles {
    display: flex;
    width: 98vw;
    flex-direction: column;
    align-items: center;
    justify-self: center;
    margin-left: 2vw;
    margin-top: 0vw;
  }
  .titles h1 {
    margin-left: 0vw;
    font-size: 3vw;
    margin-top: 6vw;
  }
  .titles h2 {
    margin-left: 0vw;
    font-size: 3vw;
    margin-top: 1vw;
  }
  .titles h3 {
    display: none;
  }
  .titles img {
    position: absolute;
    margin-top: 6vw;
    margin-left: -45vw;
    width: 5%;
  }
  .celphone-image img {
    z-index: 1;
  }
}
