.footer {
  background-color: #393939;
  text-align: center;
  color: #ffffff;
  font-family: "Poppins", sans-serif;
  padding-top: 5vw;
  padding-bottom: 0.5vw;
  margin-bottom: 10vw;
}

.footer h2 {
  margin-top: 10%;
  font-weight: bold;
  font-size: 7vw;
}

.footer ul {
  list-style: none;
  text-align: start;
  margin-left: 15vw;
  margin-bottom: 10vw;
}

.footer li img {
  width: 5vw;
  margin-right: 5vw;
}

.footer li {
  font-size: 4vw;
  margin-top: 5vw;
}

.footer a {
  text-decoration: none;
  color: inherit;
}

.footer-meta-position{
  display: flex;
}

.footer h6 {
  font-size: 3.5vw;
  margin-top: 1vw;
  width: 50%;
}

@media screen and (min-width: 1001px) {
  .footer h2 {
    margin-top: 0vw;
    font-size: 2vw;
  }

  .footer ul {
    margin-left: 30vw;
    margin-bottom: 5vw;
  }

  .footer li img {
    width: 2.5vw;
  }

  .footer li {
    font-size: 1.5vw;
  }

  .footer-meta-position{
    display: flex;
  }

  .footer h6 {
    font-size: 1.5vw;
    margin-top: 1vw;
  }
}
