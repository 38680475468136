.smartSecurity h2 {
  color: #1b73c3;
  text-align: center;
  font-size: 6vw;
  padding-bottom: 5vw;
}
.smartSecurity hr {
  width: 80%;
}

.item {
  display: grid;
  grid-template-columns: 50vw;
}

.item img {
  grid-column: 1/1;
  justify-self: center;
  width: 70%;
}

.item p {
  font-family: "Oxygen", sans-serif;
  grid-column: 2/2;
  margin-left: 1vw;
  align-self: center;
  font-size: 6vw;
}

@media screen and (min-width: 1001px) {
  .smartSecurity h2 {
    color: #08355f;
    font-size: 3vw;
  }
  .smartSecurity hr {
    display: none;
  }

  .items-list {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
  }
  .item {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .item img {
    justify-self: center;
    width: 10vw;
  }

  .item p {
    font-family: "Oxygen", sans-serif;
    margin-left: 0vw;
    width: 15vw;
    text-align: center;
    font-size: 2vw;
  }
}
