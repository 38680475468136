input[type="submit"],
select,
button {
  -webkit-appearance: none;
}

.message {
  position: absolute;
  width: 80%;
}

.form {
  /* margin-left: 2.5vw; */
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #e4e4e4;
  width: 90vw;
  border-radius: 6px;
  padding: 1rem;
  margin-bottom: 10vw;
  font-family: "Poppins", sans-serif;
  filter: drop-shadow(2px 3px 4px rgb(0, 0, 0, 0.5));
}

.contact-form {
  display: flex;
  flex-direction: column;
  font-weight: 700;
}

.form h2 {
  color: #303030;
  font-size: 4vw;
  font-weight: bold;
  text-align: center;
}
.form h3 {
  font-size: 3.3vw;
  color: #555555;
  margin-top: -1vw;
  text-align: center;
}

input {
  padding: 12px;
  height: 6vw;
  background-color: #ffffff;
  border-radius: 5px;
  border: 0;
}

::placeholder {
  font-size: 4vw;
  font-weight: 600;
}

label {
  color: #3d3d3d;
  font-size: 4vw;
  font-weight: normal;
  margin-top: 0.5rem;
  font-weight: 500;
}

button[type="submit"] {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 4vw;
  background-color: #fdca02;
  border-radius: 6px;
  border: 0;
  height: 8vh;
  filter: drop-shadow(0px 4px 4px rgb(0, 0, 0, 25));
}

button[type="submit"]:active {
  margin-top: 1.8rem;
  filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
  margin-bottom: 0.7rem;
}

.checkForm {
  display: grid;
  grid-template-columns: 0.2fr 1fr;
  grid-template-rows: 1fr;
}

.conditions {
  display: grid;
  grid-column: 2/2;
  grid-row: 1/1;
  align-items: center;
  justify-self: center;
}

.checkbox-container {
  display: grid;
  grid-column: 1/1;
  grid-row: 1/1;
}

span.checkLabel {
  margin-top: 1rem;
  width: 100%;
  font-size: 3.5vw;
  font-weight: 500;
}

input[type="checkbox"] {
  margin-left: 25%;
  width: 40%;
  margin-top: 40%;
}

.popup {
  width: 86vw;
  height: 30%;
  background-color: #555555;
  color: #ffffff;
  text-align: center;
  border-radius: 6px;
  position: absolute;
  z-index: 100;
  bottom: 35%;
  overflow-y: scroll;
}

.popuptext {
  display: none;
}

.popuptext-show {
  display: flex;
}

@media screen and (min-width: 720px) {
  button[type="submit"] {
    height: 8vw;
  }
}

@media screen and (min-width: 1001px) {
  .form {
    width: 80vw;
    padding: 2rem;
    filter: drop-shadow(0px 0px 0px rgb(0, 0, 0));
    border-radius: 0;
  }

  .form h2 {
    font-size: 1.8vw;
    text-align: center;
    grid-column: 1/3;
  }

  .form h3 {
    font-size: 1.5vw;
    grid-column: 1/3;
    font-weight: 500;
    margin-top: 0vw;
  }
  .contact-form {
    display: grid;
    justify-items: center;
  }

  .contact-form label:nth-child(1) {
    grid-column: 1/1;
    grid-row: 1/1;
    justify-self: left;
    margin-left: 5.5vw;
  }
  .contact-form input:nth-child(2) {
    grid-column: 1/1;
    grid-row: 2/2;
    width: 75%;
  }

  .contact-form label:nth-child(3) {
    grid-column: 2/2;
    grid-row: 1/1;
    justify-self: left;
    margin-left: 5.5vw;
  }
  .contact-form input:nth-child(4) {
    grid-column: 2/2;
    grid-row: 2/2;
    width: 75%;
  }

  .contact-form label:nth-child(5) {
    grid-column: 1/3;
    grid-row: 3/3;
    justify-self: left;
    margin-left: 21vw;
  }
  .contact-form input:nth-child(6) {
    grid-column: 1/3;
    grid-row: 4/4;
    width: 45%;
    justify-self: center;
  }

  input,
  select {
    height: 2.5vw;
    font-size: 0.8vw;
    border-radius: 0;
    border: 1px solid #000;
  }

  ::placeholder {
    font-size: 0.9vw;
  }

  label {
    font-size: 1.3vw;
  }

  .popup {
    width: 93%;
    height: 50%;
    font-size: 1.2vw;
    margin-left: -19vw;
  }

  .conditions {
    justify-self: start;
  }

  .checkForm {
    grid-column: 1/3;
    grid-row: 5/5;
    justify-self: center;
  }

  span.checkLabel {
    margin-top: 5%;
    width: 100%;
    font-size: 1.3vw;
  }

  input[type="checkbox"] {
    margin-left: 30%;
    margin-top: 25%;
    width: 60%;
    height: 2vw;
    grid-column: 1/3;
    grid-row: 5/5;
  }

  button[type="submit"] {
    font-size: 1.4vw;
    font-weight: 600;
    height: 7vh;
    grid-column: 1/3;
    grid-row: 6/6;
    width: 50%;
    justify-self: center;
    border: 1px solid black;
  }
}
